@font-face {
    font-family: AvenirRegular;
    src: url("../assets/fonts/AvenirNextLTPro-Regular.otf") format("opentype");
}

@font-face {
    font-family: AvenirBold;
    src: url("../assets/fonts/AvenirNextLTPro-Demi.otf") format("opentype");
}

.svgFont {
    font-family: AvenirRegular
}

body,
html {
    height: 100%;
    position: relative;
}

.bg-blue {
    background-color: #19406f;
}

.card-body-h-vh {
    /* height: calc(100vh - 123px); */
    /* overflow: auto; */
}

.card-help {
    height: calc(100vh - 0px);
    overflow: auto;
}

.time-zone-container .time-zone-radio-group {
    width: 50%;
    position: relative;
    margin-bottom: 4px;
    display: flex;
    flex-wrap: wrap;
}

.time-zone-container .time-zone-radio-group:nth-child(even) {
    text-align: right;
}

.time-zone-radio-group .time-zone-radio {
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    width: calc(100% - 10px);
    height: 28px;

}

.time-zone-radio-group .time-zone-label {
    color: #284c78;
    border: 1px solid #284c78;
    width: calc(100% - 10px);
    height: 28px;
    -moz-border-radius: 15px;
    -webkit-border-radius: 15px;
    border-radius: 15px;
    text-align: center;
    cursor: pointer;
    position: absolute;
    left: 0;
}

.time-zone-container .time-zone-radio-group:nth-child(even) .time-zone-label {
    right: 0;
    left: inherit;
}

.time-zone-radio-group .time-zone-radio:checked+.time-zone-label {
    background-color: #f0f7fa;
}

.time-zone-radio-group .time-zone-radio:checked+.time-zone-label {
    background-color: #636363;
    border-color: #636363;
    font-weight: 700;
}

.card-footer-custom {
    color: #284c78;
    border: none;
}

.btn-outline-light-primary {
    background-color: #f0f7fa;
    border-color: #284c78;
    color: #284c78;
}

.btn.btn-outline-light-primary:hover {
    background-color: #f0f7fa;
    border-color: #284c78;
    color: #284c78;
}

.btn.btn-outline-light-primary:active {
    background-color: #f0f7fa;
    border-color: #284c78;
    color: #284c78;
}

.btn.btn-outline-light-primary:focus {
    background-color: #f0f7fa;
    border-color: #284c78;
    color: #284c78;
}

.curved-button {
    -moz-border-radius: 20px;
    -webkit-border-radius: 20px;
    border-radius: 20px;
    width: calc(50% - 10px);
    height: 28px;
    line-height: 1;
}

.curved-button-auto-w {
    -moz-border-radius: 20px;
    -webkit-border-radius: 20px;
    border-radius: 20px;
    min-width: 100px;
    height: 28px;
    line-height: 1;
}

.text-blue {
    color: #284c78;
}

.modal-loading {
    max-width: 200px;
    margin-left: auto;
    margin-right: auto;
    line-height: 18px;
}

.modal-loading .modal-content {
    border-radius: 13px;
    z-index: 999!important;
}

.loading-img {
    max-width: 100%;
}

.modal-loading-backdrop {
    background-color: rgba(25, 64, 111, .5);
    position: fixed;
    width: 100%;
    height: 100%;
    overflow: hidden;
    left: 0;
    top: 0;
}

.list-bullets li {
    line-height: 20px;
    margin-bottom: 3px;
}

.bg-card {
    background-attachment: fixed;
    border-radius: 0px 0px 10px 10px;
}

.card-custom.card {
    border-radius: 10px;
}

.card-custom .card-header {
    border-radius: 10px 10px 0 0;
}

.card-custom .card-footer {
    border-radius: 0 0px 10px 10px;
}

.card-help .card-body {
    position: relative;
    display: flex;
    flex-direction: column;

}

.help-card-bottom {
    position: relative;
    bottom: 30px;
    left: 0;
    right: 0;
    text-align: center;

    padding: 0;
    display: flex;
    margin-top: auto;

}


.help-card-bottom::after {
    content: '';
    position: absolute;
    bottom: 0;
    height: 180px;
    width: 100%;
    left: 0;
    right: 0;
    background-color: #fff;
    z-index: 0;
    display: none;
}

.card-help-body {
    border-radius: 7px;
    padding: 15px;
    position: relative;
    z-index: 1;
    max-height: calc(100vh - 42px);
    overflow: auto;
}

.calender-picker {
    position: relative;
}

.calendar-dropdown {
    position: absolute;
    right: 10px;
    top: calc(50% - 9px);
    border: solid #284c78;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 5px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
}

.form-control-datepicker {
    color: #284c78;
    border-color: #284c78;
    border-radius: 23px;
}

.modal-date-picker {

    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    display: none;
    align-items: center;
    justify-content: center;
    position: fixed;
    background-color: rgba(25, 64, 111, .5);
}

.modal-date-picker .datepicker-dropdown {
    top: 0 !important;
    position: static;
    width: 80%;
    max-width: 400px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 5px -3px, rgba(0, 0, 0, 0.14) 0px 8px 10px 1px, rgba(0, 0, 0, 0.12) 0px 3px 14px 2px;

}

.modal-date-picker .datepicker-dropdown table {
    width: 100%;
}


.branding-img {
    max-height: 20px;
}

.text-gray {
    /* color: #888991; */
    color: #0D1C3D;
}

.divider-content-group {
    border-bottom: 1px solid #dedddf;
    /* margin-bottom: 10px;
    padding-bottom: 10px; */
    padding-top: 0;
}

.divider-content-group:last-child {
    border-bottom: 0;
}

.content-left-checkbox {
    width: calc(100% - 50px);
}

.chec-box-columns {
    width: 50px;
    text-align: center;
}

.chec-box-columns .form-check-input {
    width: 22px;
    height: 22px;
    border-color: #19406f;
}

.chec-box-columns .form-check-input:checked[type=checkbox] {
    background-color: #1f4175;
    --bs-form-check-bg-image: url('./assets/bg-checkbox.png');
    background-position: center;
    background-size: 14px;
}

.tab-images {
    height: 75px;

}

.health-box {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    flex-direction: column;
    padding: 5px;
    border: 2px solid transparent;
    border-radius: 5px;
    text-decoration: none;
    font-weight: 500;
    color: #385d61;
}

.health-box.active {
    color: #385d61;
}

label.text-gray {
    font-weight: 600;
}

.health-good.active {
    background-color: #d2fcd2;
    border: 2px solid #1a3f6f;
    border-radius: 10px;
}

.health-notsure.active {
    background-color: #fff1b8;
    border: 2px solid #1a3f6f;
    border-radius: 10px;
}

.health-bad.active {
    background-color: #ffd2d2;
    border: 2px solid #1a3f6f;
    border-radius: 10px;
}

.form-group-textarea {
    position: relative;
}

.charector-count {
    position: absolute;
    right: 10px;
    bottom: 5px;
    font-size: 12px;
}

.btn-submit-cureved {
    min-width: 155px;
    min-height: 35px;
    border-radius: 35px;
    color: #fff !important;
    background-color: #1a3d71;
    position: relative;
    justify-content: center;
    border: none !important;
}

.btn-submit-cureved:hover,
.btn-submit-cureved:active,
.btn-submit-cureved:focus {
    color: #fff !important;
    background-color: #1a3d71 !important;
}

.btn-icon {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    display: flex;
    position: absolute;
    left: 0;
    background-image: url('./assets/Check_Blue.svg')
}

.backgroundImage {
    width: 100px;
    background-image: url(./assets/Bad.svg);
}

.titlePos {
    position: absolute;
    top: 65px;
    font-weight: 600;
    font-size: 12px;
}

.animation-div {
    transition: all 5s;
}

/* .fadeIn {
    opacity: 1;
    transition: opacity 0.3s ease-in;
}

.fadeOut {
    opacity: 0;
    transition: opacity 0.3s all;
} */

/* .fadeOut{
    height: 0px;
    transition: height 2s linear;

}
.fadeIn{
    overflow-y: auto;
    transition: height 2s linear;
} */

.otherSecCollapse {
    transition: height 500ms linear;
    overflow: hidden;
}


.otherSecCollapse1 {
    transition: all 400ms linear;
    transition-property: height, opacity;
    overflow: hidden;
}

.otherSecCollapse2 {
    transition: opacity 1000ms;
}

.qstDescription,
.qstSubOptions {
    height: 0px;
    visibility: hidden;
}

.btnSubmitText {
    margin-top: 2px;
    margin-left: 10px;
}

.btn-submit-cureved:disabled {
    cursor: not-allowed;
    opacity: 0.5;
}


/* @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
  .otherSecCollapse1 {
    animation: fadeIn 0.2s ease-in-out;
  } */
.bg-card {
    background-size: contain;
}

.help-card-1 {
    bottom: 45px;
}

.help-card-2 {
    bottom: 180px !important;
}
img.image-set {
    bottom: -18px;
    position: absolute;
    z-index: 998;
    left: 90px;
}
.bg-transparency {
    background-color: rgba(13, 28, 61, 0.9);
}
.global-button a {
    color: #0D1C3D;
    text-decoration: none;
    font-size: 18px;
}
.global-button{
 border:1px solid #0D1C3D;
}
@media only screen and (max-width: 768px) {
    .help-card-bottom {
        bottom: 45px;
    }

    .bg-card {
        background-size: cover;
    }
    img.image-set {
   
        left:0px;
    }
}
@media only screen and (min-width: 820px) and (max-width: 1240px) {
    .bg-card {
        background-size: cover;
    }

    .bg-card1 {

        background-size: contain;
    }
}

@media only screen and (min-width: 1000px) and (max-width: 1440px) {
    .bg-card1 {
        background-position: center;
        background-size: auto
    }

    .card-help-body {
        border-radius: 7px;
        padding: 15px;
        position: relative;
        z-index: 1;
        width: 76%;
        margin: auto;
    }

}

@media only screen and (min-width: 1025px) and (max-width: 1440px) {
    .card-help-body {
        width: 74%;
    }
}
@media only screen and (min-width: 820px) and (max-width: 820px) {
    .card-help-body {
        width: 74%;
    }
    .card-help-body {
        border-radius: 7px;
        padding: 15px;
        position: relative;
        z-index: 1;
        width: 75%;
        margin: auto;
    }
}

@media only screen and (min-width: 1024px) and (max-width: 1024px) {
    .help-card-bottom {
        bottom: 210px;
    }
    .bg-card1 {
        background-position: center;
        background-size: cover;
    }
}

@media only screen and (min-width: 820px) and (max-width: 820px) {
    .help-card-bottom {
        bottom: 90px;
    }
}