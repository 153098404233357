
.stepper-size-320,.stpper-details-page {
    width: 325px;
}
.text-light-grey {
    color: #949494;
}
.text-sm {
    font-size: .852rem;
}
.stepper-conatainer {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
}
.steps-group {
    display: flex;
    position: relative;
}
.steps-group.active .btn-outline-primary-steps {
    background-color: #F0F7FA;
    color: #0D1C3D !important;
}
.steps-group.completed .btn-outline-primary-steps {
    background-color: #0D1C3D;
    color: white !important;
}
.steps-group.preview .btn-outline-primary-steps {
    background-color: #fff;
    color: #0D1C3D !important;
}
.steps-group.preview button:hover, .steps-group.active button:hover{
    background-color: #0D1C3D !important;
    color: white !important;
}
.steps-circle {
    width: 30px;
    height: 30px;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
    border-radius: 50%;
    border: 3px solid #e8e8e8;
    line-height: 25px;
    font-size: 15px;
    margin-right: 4px;
    background-color: #fff;
    color: #0078aa;
    z-index: 100;
}
.steps-details-list {
    width: 100%;
    margin-top: 5px;
    padding-bottom: 40px;
}
.steps-group:last-child .steps-details-list {
    padding-bottom: 10px;
}
.steps-group::after {
    content: '';
    display: flex;
    height: 100%;
    position: absolute;
    width: 3px;
    background-color: #e8e8e8;
    left: 13px;
    z-index: 10;
}
.steps-group:last-child::after{
    content: none !important;
}

.btn-outline-primary-steps {
    border-color: #0D1C3D !important;
    border-radius: 24px;
    width: 100%;
}
.btn-outline-curved {
    border-radius: 24px;
}
.btn-outline-primary-steps:hover,
.btn.btn-outline-primary-steps:first-child:active,
:not(.btn-check)+.btn.btn.btn-outline-primary-steps:active
{
    border-color: #0078aa;
    background-color: transparent;
    color: #0078aa;
}

.form-check-size-22 {
    width: 22px;
    height: 22px;
}
.form-check-size-18 {
    width: 18px;
    height: 18px;
}
.list-group-form {
    border: none;
}
.list-group-form li {
    padding: 0;
    margin-bottom: 10px;
    border: none;
}
.list-group-form li:last-child {
    margin-bottom: 0;
}
.page-section-group:first-child {
    margin-top: 0;
}
.page-section-group{
    margin-top: 0;
    margin-bottom: 25px;
}
.page-section-divider::after{
    content: '';
    width: 100%;
    margin: 25px auto 0 auto;
    height: 2px;
    display: block;
    border-bottom: 2px solid #a1a1a1;
}
.list-circle-bullet {
    padding-left: 1rem;
}
.stepper-bullets-list li {
    margin-bottom: 0.65rem;
    padding-left: 5px;
}
.avatar-image {
    width: 64px;
}
.clock-image-container {
    /* margin-left: -8px; */
    margin-right: 0.65rem;
}
.avatar-plus-icon {
    font-size: 51px;
    font-weight: 500;
    margin: 0 1rem;
    color: #0078aa;
}
.form-check-input.form-check-input-primary:focus,.form-check-input.form-check-input-action:focus {
   
    border-color: #0078aa;
    box-shadow: none;
}
.form-check-input.form-check-input-action {
    border-radius: 0;
}
.form-check-input.form-check-input-primary:checked[type=checkbox],
.form-check-input.form-check-input-action:checked[type=checkbox]
{
    background-color: transparent;
    /* background-image: url('../images/check-image-sharp.svg'); */
    border-color: #073c66;
    box-shadow: none;
    background-size: 20px;
    background-position: -1px -3px;

}
.form-check-input.form-check-input-primary:checked[type=checkbox]{
    background-size: 24px;
    background-position: -1px -4px;
}
.clock-icon {
    width: 35px;
    height: 35px;
    
}
.appointment-time {
    font-size: 12px;
    text-align: center;
    margin-top: -5px;
}
.links {
    color: #0078aa;
}
.patient-avatar{
    margin: 1.0rem 0;
}

@media (max-width: 359.98px) {
    .stepper-size-320 {
        width: 95%;
    }
    .stpper-details-page {
        width: 100%;
    }
}
@media (max-width:1024.98px) {
    .stpper-details-page {
        width: 100%;
    }
}
  