/* .card-custom.card {
    border-radius: 10px;
}
.card-custom .card-header {
    border-radius: 10px 10px 0 0;
} */

/* .card-custom.card {
    border-radius: 10px;
}
.card-custom .card-header {
    border-radius: 10px 10px 0 0;
} */


.preview_container .card-custom.card {
    border-radius: 0px !important;
    border: 0px !important;

}
.preview_container .card-custom .card-header {
    border-radius: 0px !important;
}

.preview_container  .react-datepicker__month-container{
    width: 100% !important;
    font-family: 'Bahnschrift' !important;
}

.preview_container    .bg-blue {
    background-color: #19406f !important;
}
.preview_container   .text-blue{
    color: #19406f !important;
}
.preview_container   .form-control-datepicker {
    color: #284c78 !important;
    border-color: #284c78 !important;
    border-radius: 23px !important;
}
.preview_container .time-zone-container .time-zone-radio-group {
    width: 50%;
    position: relative;
    margin-bottom: 8px;
    display: flex;
    flex-wrap: wrap;
}
.time-zone-radio-group .time-zone-radio {
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    width: calc(100% - 10px);
    height: 28px;
    border-radius: 15px;

}
.preview_container .time-zone-radio-group .time-zone-label {
    color: #284c78;
    border: 1px solid #284c78;
    width: calc(100% - 10px);
    height: 28px;
    -moz-border-radius: 15px;
    -webkit-border-radius: 15px;
    border-radius: 15px;
    text-align: center;
    cursor: pointer;
    position: absolute;
    left: 0;
    padding-top: 2px;
    font-family: 'Bahnschrift' !important;
}

.preview_container .time-zone-radio-group .time-zone-radio:checked+.time-zone-label {
    background-color: #003B71;
    border-color: #003B71;
    font-weight: 700;
    color: white;
}

.preview_container .curved-button {
    -moz-border-radius: 20px;
    -webkit-border-radius: 20px;
    border-radius: 20px !important  ;
    width: calc(50% - 10px);
    height: 40px;
    line-height: 1;
}
.preview_container .btn-outline-light-primary {
    background-color: #f0f7fa !important;
    border-color: #284c78 !important;
    color: #284c78 !important;
}

.preview_container .btn:hover, .btn:active, .btn:focus{
    background-color: #e76b13fa!important;
    color: white !important;
}
.preview_container .btn{
    color: #e76b13fa!important;
}
.preview_container .bg-card {
    background-position: center 8%;
}

.preview_container .bg-card1 {
    background-position: center 8%;
}

.preview_container .bg-card2 {
    background-position: center 8%;
}
.preview_container .bg-card3 {
    /* background: url(../assets/images/img7.jpg) no-repeat center / cover; */
    /* background-attachment: fixed;
    border-radius: 0px 0px 10px 10px; */
    background-position: center 8%;
}
.preview_container  .card-help .card-body {
    position: relative;
    display: flex;
    flex-direction: column;
}

.preview_container .react-datepicker__day:hover{
    background-color: #46627c49 !important;
}

.preview_container   .help-card-bottom{
    position: relative;
    bottom: 45px;
    left: 0;
    right: 0;
    text-align: center;
    padding: 20px;
    display: flex;
    margin-top: auto;
}
.preview_container   .help-card-bottom::after {
    content: '';
    position: absolute;
    bottom: 0;
    /* height: 180px; */
    width: 100%;
    left: 0;
    right: 0;
    background-color: #fff;
    z-index: 0;
}
.preview_container   .card-help-body {
    border-radius: 7px;
    padding: 15px;
    position: relative;
    z-index: 1;
    width: 96%;
    margin: auto;
    -webkit-border-radius: 7px;
    -moz-border-radius: 7px;
    -ms-border-radius: 7px;
    -o-border-radius: 7px;
}
.preview_container   .card-body-h-vh {
 /* height: calc(100vh - 123px);  */
 height: calc(100vh - 0px); 
     overflow: auto; 
}
.preview_container   .btn-outline-light-primary {
    background-color: #f0f7fa;
    border-color: #284c78;
    color: #284c78;
}
.preview_container   .card-footer-custom {
    color: #284c78;
    border: none;
}
.preview_container   .curved-button-auto-w {
    -moz-border-radius: 20px;
    -webkit-border-radius: 20px;
    border-radius: 20px !important;
    min-width: 100px;
    height: 28px;
    line-height: 1;
}
.modal-loading {
    max-width: 200px;
    margin-left: auto;
    margin-right: auto;
    line-height: 18px;
}
.modal-loading .modal-content {
    border-radius: 13px;
}
.loading-img {
    max-width: 100%;
}
.modal-loading-backdrop {
    background-color: rgba(25,64,111, .5);
    position: fixed;
    width: 100%;
    height: 100%;
    overflow: hidden;
    left: 0;
    top: 0;
}

.preview_container   .cancel_enrollment{
    border: 1px solid #284c78;
}
.preview_container   .cander_red{
    border: 1px solid #284c78;
    border-radius: 15px;
    padding: 8px
}
.preview_container .opicity-5{
    opacity: 0.8;
}

.preview_container .not-allowed {
    cursor: not-allowed !important;
  }

  .preview_container .react-datepicker__day{
    height: 1.7rem!important;
  }
  
  .preview_container .react-datepicker__day:hover{
    border-radius: 50%!important;
}
.preview_container   .img-container {
    text-align: left;
   }
   .preview_container .img-container img {
     height: 100px;
 }
 .preview_container .parent-elxo h6 ,a{
     font-size:16px;
     font-weight: 500;
 }
 .preview_container .parent-elxo h4 {
   font-size: 20px;
        text-align: center;   
 }
 .preview_container .parent-elxo h6 {
    text-align: center;
  }

  .preview_container .out_service_logowrapper img{
    max-width: 150px;
    width: 100%;
    height: auto;
  }

 @media screen and (max-width: 576px) {
    .preview_container    .img-container {
        text-align: center;
        width:150px;
       }
       .preview_container .parent-elxo h6 {
      text-align: left;
      text-align: justify;
      font-size: 16px;
      font-weight:500;
    }
    .preview_container .parent-elxo h4 {
    text-align: center;
    font-size: 20px;
    font-weight:500;
    }
    .preview_container  body .uwy.userway_p7 .userway_buttons_wrapper {
        top: 30px !important;
        right: -20em!important;
        bottom: auto !important;
    }
    .preview_container  .uwy.userway_p5 .userway_buttons_wrapper {
        top: 23px !important;
        right: 18px !important;
    }
    .preview_container  .uwy .userway_buttons_wrapper {
        display: flex !important;
        flex-direction: column!important;
        align-items: end !important; 
        
    }
    .preview_container   body .uwy.userway_p5 .userway_buttons_wrapper {
        justify-content: initial !important; 
       
    }
    .preview_container   .uwy.userway_p5 .userway_buttons_wrapper {
     
        justify-content: initial !important;
    }
  

     .preview_container  body .uwy.userway_p7 .userway_buttons_wrapper {
        top: 13px;
        /* left: 13px; */
        right: auto;
        bottom: auto;
        right: 13px;
    }
    .preview_container   body  .userway_buttons_wrapper {
        top: 13px;
        /* left: 13px; */
        right: auto;
        bottom: auto;
        right: 13px;
    }
  }

   .preview_container body .uwy.userway_p7 .userway_buttons_wrapper {
    top: 26px !important;
    bottom: auto;
    right: 13px !important;
}

.uwy.userway_p7 .userway_buttons_wrapper{
    left: unset !important;
    right: 13px !important;
}
 .uwaw .main{right: 0!important;}


 .preview_container body {
    margin: 0;
    font-family: 'Bahnschrift' !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  .preview_container h5{
    font-family: 'Bahnschrift' !important;
  }
  
  .preview_container button{
    border-color: #0D1C3D !important;
  }
  
  .preview_container .w-15{
    width:200px;
  }
  
   .preview_container .react-datepicker__day{
    border: 1px solid #D3D6DC !important;
    border-radius: 50%;
    color: #284c78!important;
    font-family: 'Bahnschrift' !important;
  }
   
  .preview_container .react-datepicker__day--selected{
    border-radius: 50% !important;
    color: white !important;
    font-family: 'Bahnschrift' !important;
  }
  
  .preview_container .react-datepicker__day--keyboard-selected{
  background-color: white !important;
  border-radius: 50% !important;
  }
   
  .preview_container .react-datepicker__day--disabled{
    border-radius: 50% !important;
    background-color: #D3D6DC !important;
    color: #284c78 !important ;
    cursor: not-allowed !important;
    font-family: 'Bahnschrift' !important;

  }
   
  .preview_container .react-datepicker__header{
    background-color: white !important;
  }
  
  .preview_container .react-datepicker__day-names, .react-datepicker__week{
    display: flex;
    justify-content: space-between;
  }
  
  .preview_container .react-datepicker{
    font-family: AvenirRegular, -apple-system, BlinkMacSystemFont, 'Helvetica Neue', sans-serif !important;
    text-transform: uppercase;
    border: 0px !important;
  }

  .preview_time_cont{
    padding-left: 10px;
  }

  .loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 75vh;
    opacity: 1;
    transition: opacity 0.5s ease-in-out;
  }
  
  .loader.hidden {
    opacity: 0;
    visibility: hidden;
  }
  
  .preview_container {
    opacity: 1;
    transition: opacity 0.5s ease-in-out;
  }
  
  .preview_container.loading {
    opacity: 0.5;
    pointer-events: none;
  }

  .preview_container .fa-spinner {
    font-size: 25px;
    color: #E46725 ;

  }
  
  .preview_container .cander_red {
    border-radius: 4px !important;
  }

  .pickatime {
    text-align: center;
    font-size: 18px;
    color:#284c78;
    font-family: 'Bahnschrift' !important;
    margin-bottom: 10px;
  }