.common_button_wrapper .section_btn {
    color: #284c78;
    font-size: 15px;
    text-transform: capitalize;
    font-weight: 500;
    border: 0;
    outline: none;
    border-radius: 30px;
    min-width: 44px;
    height: 44px;
    width: 44px;
    padding: 8px 5px;
    white-space: nowrap;
    font-family: "Bahnschrift" !important;
  }
  
  .important-border {
    border-color: var(--border-color) !important;
  }
  
  .direction_icon {
    width: 60px;
    height: 60px;
    background: url("../../../../../src/assets/images/directions_icon.svg");
    background-repeat: no-repeat;
    cursor: pointer;
  }


  

  .navigation-buttons {
    margin-top: auto; 
  }

  .ql-align-right{
    text-align: right;
  }
  .ql-align-justify{
    text-align: left;
  }
  .ql-align-center{
    text-align: center;
  }

  .control_padding{
    padding: 0 15px;
    margin-bottom: 15px;
  }

  .editor-inner-text ul{
    padding-left: 1rem;
  }

  .text_clamp{
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .custom_fade_model{
    background: rgb(76, 74, 74, 0.8);
  }