.modal-loading {
    max-width: 200px;
    margin-left: auto;
    margin-right: auto;
    line-height: 18px;
}
.modal-loading .modal-content {
    border-radius: 13px;
}
.loading-img {
    max-width: 100%;
}
.modal-loading-backdrop {
    background-color: rgba(25,64,111, .5);
    position: fixed;
    width: 100%;
    height: 100%;
    overflow: hidden;
    left: 0;
    top: 0;
}
.text-red{
    color: red;
}
.react-datepicker__month-container{
    display: grid !important;
    float: none !important;
}
.react-datepicker {
   width:100% !important
}

.modal-content{
    margin-top: 61px;
}
.zindex{
    z-index: 999!important;
}