.text-inky-blue{
  color: #0D1C3D !important
}

div[role="dialog"] button[title="Close"] {
  right: 10px !important;
  top: 10px !important;
  width: 12px !important;
  height: 12px !important;
}

div[role="dialog"] button[title="Close"] span{
  width: 12px !important;
  height: 12px !important;
  margin-left: 5px !important;
  margin-top: 0px !important;
}

.main {
  margin: 50px auto;
  width: 410px;
  padding: 40px;
}

.w-20{
  width:20px;
}

.f-16{
  font-size: 16px;
}
.f-12{
  font-size: 12px;
}

.width100px{
  width: 40px !important;
  height: 40px;
}
.header-title {
  display: flex;
  justify-content: center;
  
}
.sub-header {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  font-size: 12px;
  color: #0D1C3D;
}
.action-header {
  display: flex;
  justify-content: flex-start;
  margin-top: 16px;
  align-items: center;
}
.action-title {
  margin-left: 10px;
}
.action-tagline {
  display: flex;
  justify-content: center;
  margin-top: 5px;
}

.button-list {
  padding: 10px;
  width: 100%;
  margin-top: 16px;
  border: 1px solid #19406f !important;
  background: #fff;
  border-radius: 20px;
  cursor: pointer;
  color: #19406f !important;
}
input[type="checkbox"] {
  margin: 4px 0 0;
  line-height: normal;
  width: 20px;
  height: 20px;
  accent-color: #0D1C3D !important;
}
.selected {
  padding: 10px;
  width: 100%;
  margin-top: 16px;
  border: 1px solid #0D1C3D !important;
  background: #0D1C3D !important;
  border-radius: 20px;
  color: #ffff;
  cursor: pointer;
}

@media only screen and (max-width: 991px) {
  .main {
    width: 100% !important;
    padding: 25px;
    margin: 0px auto;
  }
  .action-tagline {
    margin-top: 5px;
  }
  .alarm-icon {
    width: 49px !important;
    height: 22px !important;
  }
}
.image-main {
  display: flex;
  margin-top: 16px;
  justify-content: space-between;
}
.image-text {
  width: 65%;
  
}
.bullet-point {
  margin-top: 10px;
}
.bullet-point li {
  
 }
.description {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  
}
.check-list {
  margin-top: 5px;
  /* display: flex;
  justify-content: space-between; */
}
.check-list-title {
  margin-top: 20px;
  
}
.check-list-description {
  margin-top: 5px;
  max-width: 280px;
  
}
.action-tile {
  margin-top: 20px;
  
}
.alarm-icon {
  height: 42px;
  width: 60px;
}
.cross-icon {
  height: 30px;
  width: 30px;
}
.cancel-main {
  display: flex;
  color: red;
}
.success-main {
  display: flex;
  color: green;
  margin-top: 10px;
}
.right-icon {
  height: 30px;
  width: 30px;
}
.check-list-detail {
  display: flex;
  justify-content: space-between;
}
.double-image img {
  height: 125px;
  width: 130px;
}
.double-image-main {
  display: flex;
  justify-content: space-between;
}
.svg.plus-icon {
  height: 40px;
  width: 40px;
}

.width24px{
  width: 24px !important;
  height: 24px !important;
}

.inactiveButton{
    cursor: not-allowed;
    opacity: 0.5;
    color: white !important;
    background-color: #0D1C3D !important;
  border: 1px solid #0D1C3D !important;
}

.cursor-pointer { 
  cursor: pointer; 
}

.dateofbirth {
  padding: 10px 10px;
  margin-top: 10px;
  border: 1px solid #254a70;
  background: #fff;
  border-radius: 20px;
  color: #254a70;
  font-family: sans-serif;
  cursor: pointer;
  width: 100px;
}
.dobsubmit {
  padding: 10px 10px;
  margin-top: 10px;
  margin-left: 15px;
  border: 1px solid #254a70;
  background: #254a70;
  color: #fff;
  border-radius: 20px;
  cursor: pointer;
  width: 100px;
}

.dobsection{
  display: flex;
  justify-content: center;
  align-items: center;
}

.calendar-dropdown{
  transform: rotate(0deg) !important;
  border: 0px !important;
}

.min-150-width{
  min-width: 150px;
}

.min-250-width{
  min-width: 250px;
}

.w-40px{
width: 40px;
}

.paddingLeft11px{
  padding-left: 11px;
}

.paddingLeft15px{
  padding-left: 15px;
}

.marginLeft9px{
  margin-left: 9px;
}

.marginBottom25Rem{
  margin-bottom: 2.5rem !important;
}

.ps35px{
padding-left: 35px;
}

.ps20px{
  padding-left: 20px;
  }