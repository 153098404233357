/* .card-custom.card {
    border-radius: 10px;
}
.card-custom .card-header {
    border-radius: 10px 10px 0 0;
} */

/* .card-custom.card {
    border-radius: 10px;
}
.card-custom .card-header {
    border-radius: 10px 10px 0 0;
} */


.card-custom.card {
    border-radius: 0px !important;
    border: 0px !important;
}
.card-custom .card-header {
    border-radius: 0px !important;
}

.bg-blue {
    background-color: #19406f !important;
}
.bg-inky{
    background-color: #0D1C3D !important;
}
.text-blue{
    color: #19406f !important;
}
.text-inky{
    color: #0D1C3D !important;
}
.form-control-datepicker {
    color: #284c78 !important;
    border-color: #284c78 !important;
    border-radius: 23px !important;
}
.time-zone-container .time-zone-radio-group {
    width: 50%;
    position: relative;
    margin-bottom: 8px;
    display: flex;
    flex-wrap: wrap;
}
.time-zone-radio-group .time-zone-radio {
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    width: calc(100% - 10px);
    height: 28px;
    border-radius: 15px;

}
.time-zone-radio-group .time-zone-label {
    color: #284c78;
    border: 1px solid #284c78;
    width: calc(100% - 10px);
    height: 28px;
    -moz-border-radius: 15px;
    -webkit-border-radius: 15px;
    border-radius: 15px;
    text-align: center;
    cursor: pointer;
    position: absolute;
    left: 0;
    padding-top: 2px;
}
.time-zone-radio-group .time-zone-radio:checked+.time-zone-label {
    /* background-color: #636363;
    border-color: #636363;
    font-weight: 700; */
    background-color: #003B71;
    border-color: #003B71;
    font-weight: 700;
    color: white;
}
.curved-button {
    -moz-border-radius: 20px;
    -webkit-border-radius: 20px;
    border-radius: 20px !important  ;
    width: calc(50% - 10px);
    height: 28px;
    line-height: 1;
}
.btn-outline-light-primary {
    background-color: #f0f7fa !important;
    border-color: #284c78 !important;
    color: #284c78 !important;
}
.btn:hover, .btn:active, .btn:focus{
    background-color: #003B71 !important;
    color: white !important;
}
.btn{
    color: #284c78 !important;
}
.bg-card {
    background: url(../assets/images/bghelp-card.jpeg) no-repeat center / cover;
    /* background-attachment: fixed;
    border-radius: 0px 0px 10px 10px; */
    background-position: center 8%;
}
.bg-card1 {
    background: url(../assets/images/img5.jpg) no-repeat center / cover;
    /* background-attachment: fixed;
    border-radius: 0px 0px 10px 10px; */
    background-position: center 8%;
}
.bg-card2 {
    background: url(../assets/images/img2.jpg) no-repeat center / cover;
    /* background-attachment: fixed;
    border-radius: 0px 0px 10px 10px; */
    background-position: center 8%;
}
.bg-fit-card2 {
    /* background: url(../assets/images/Todo2bz.jpg) no-repeat center / cover; */
    background: url(../assets/images/Todo2bz.jpg) no-repeat center / cover;
    /* background-attachment: fixed;
    border-radius: 0px 0px 10px 10px; */
    background-position: center 8%;
}
.bg-hpvfit-card2 {
    /* background: url(../assets/images/Todo2bz.jpg) no-repeat center / cover; */
    background: url(../assets/images/Nurse_225129.jpg) no-repeat center / cover;
    /* background-attachment: fixed;
    border-radius: 0px 0px 10px 10px; */
    background-position: center 8%;
}
.bg-hpvdocfit-card2 {
    /* background: url(../assets/images/Todo2bz.jpg) no-repeat center / cover; */
    background: url(../assets/images/Doctor-225128.jpg) no-repeat center / cover;
    /* background-attachment: fixed;
    border-radius: 0px 0px 10px 10px; */
    background-position: center 8%;
}

.bg-mammo-card2 {
    /* background: url(../assets/images/Todo2bz.jpg) no-repeat center / cover; */
    background: url(../assets/images/Todo_mammo-100.jpg) no-repeat center / cover;
    /* background-attachment: fixed;
    border-radius: 0px 0px 10px 10px; */
    background-position: center 8%;
}
.bg-fitkit-card-sum{
    background: url(../assets/images/Nurse_small.svg) no-repeat center / cover;
    /* background-attachment: fixed;
    border-radius: 0px 0px 10px 10px; */
    /* background-position: center 8%; */
}
.bg-fit {
    background: url(../assets/images/FITKIT.svg) no-repeat center / cover;
    /* background-attachment: fixed;
    border-radius: 0px 0px 10px 10px; */
    background-position: center 8%;
}
.bg-fit-v2-reminder{
    background: url(../assets/images/Cornershade_BGround-100.jpg) no-repeat center / cover;
    background-position: center 8%;
   
}
.bg-fit-afam{
    background: url(../assets/images/AA2_Background.png) no-repeat center / cover;
    background-position: center 8%;
}
.bg-fit-hispanic {
    background: url(../assets/images/HA_Background.png) no-repeat center / cover;
    background-position: center 8%;
}
.bg-fit-pi {
    background: url(../assets/images/PI_Background.png) no-repeat center / cover;
    background-position: center 8%;
}

.bg-card3 {
    background: url(../assets/images/img7.jpg) no-repeat center / cover;
    /* background-attachment: fixed;
    border-radius: 0px 0px 10px 10px; */
    background-position: center 8%;
}
.card-help .card-body {
    position: relative;
    display: flex;
    flex-direction: column;
}
.help-card-bottom{
    position: relative;
    bottom: 45px;
    left: 0;
    right: 0;
    text-align: center;
    padding: 20px;
    display: flex;
    margin-top: auto;
}
.help-card-bottom::after {
    content: '';
    position: absolute;
    bottom: 0;
    /* height: 180px; */
    width: 100%;
    left: 0;
    right: 0;
    background-color: #fff;
    z-index: 0;
}
.card-help-body {
    border-radius: 7px;
    padding: 15px;
    position: relative;
    z-index: 1;
    width: 92%;
    margin: auto;
}

.exended-card-help-body {
    border-radius: 50px;
    padding: 15px;
    position: relative;
    z-index: 1;
    width: 96%;
    margin: auto;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    -ms-border-radius: 50px;
    -o-border-radius: 50px;
}
.card-body-h-vh {
 /* height: calc(100vh - 123px);  */
 height: calc(100vh - 0px); 
     overflow: auto; 
}
.btn-outline-light-primary {
    background-color: #f0f7fa;
    border-color: #284c78;
    color: #284c78;
}
.card-footer-custom {
    color: #284c78;
    border: none;
}
.curved-button-auto-w {
    -moz-border-radius: 20px;
    -webkit-border-radius: 20px;
    border-radius: 20px !important;
    min-width: 100px;
    height: 28px;
    line-height: 1;
}
.modal-loading {
    max-width: 200px;
    margin-left: auto;
    margin-right: auto;
    line-height: 18px;
}
.modal-loading .modal-content {
    border-radius: 13px;
}
.loading-img {
    max-width: 100%;
}
.modal-loading-backdrop {
    background-color: rgba(25,64,111, .5);
    position: fixed;
    width: 100%;
    height: 100%;
    overflow: hidden;
    left: 0;
    top: 0;
}

.cancel_enrollment{
    border: 1px solid #284c78;
}
.cander_red{
    border: 1px solid #284c78;
    border-radius: 15px;
    padding: 8px
}
.opicity-5{
    opacity: 0.8;
}

.not-allowed {
    cursor: not-allowed !important;
  }

  .react-datepicker__day{
    height: 1.7rem!important;
  }
  
  .react-datepicker__day:hover{
    border-radius: 50%!important;
}
.img-container {
    text-align: left;
   }
   .img-container img {
     height: 100px;
 }
 .parent-elxo h6 ,a{
     font-size:16px;
     font-weight: 500;
 }
 .parent-elxo h4 {
   font-size: 20px;
        text-align: center;   
 }
 .parent-elxo h6 {
    text-align: center;
  }

  .out_service_logowrapper img{
    max-width: 150px;
    width: 100%;
    height: auto;
  }

.btn_global{
    background-color: #F0F7FA;
    border-radius: 25px;
    padding: 6px 15px;
    max-width: 247px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.btn_global span{
  font-size: 1rem;
  font-weight: 700 !important;
  color: #0C1C3C;
  font-family: AvenirBold;
  margin-top: 2px;
}

.btn_bordered{
    background-color: #F0F7FA;
    border-radius: 25px;
    padding: 9px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #506E94;
    max-width: 250px;
    width: 100%;
    margin: auto;
}

.btn_bordered span{
    font-size: 20px;
    font-weight: 700 !important;
    color: #41618A;
    font-family: AvenirBold;
  }

 @media screen and (max-width: 576px) {
    .address_info_desc{
        font-size: 0.9rem !important;
    }

    .lbl_map_txt{
        font-size: 1rem !important;
    }

    .btn_bordered span{
        font-size: 1rem;
        font-weight: 700 !important;
        color: #41618A;
        font-family: AvenirBold;
      }

    .img-container {
        text-align: center;
        width:150px;
       }
       .parent-elxo h6 {
      text-align: left;
      text-align: justify;
      font-size: 16px;
      font-weight:500;
    }
    .parent-elxo h4 {
    text-align: center;
    font-size: 20px;
    font-weight:500;
    }
 body .uwy.userway_p7 .userway_buttons_wrapper {
        top: 30px !important;
        right: -20em!important;
        bottom: auto !important;
    }
.uwy.userway_p5 .userway_buttons_wrapper {
        top: 23px !important;
        right: 18px !important;
    }
.uwy .userway_buttons_wrapper {
        display: flex !important;
        flex-direction: column!important;
        align-items: end !important; 
        
    }
  body .uwy.userway_p5 .userway_buttons_wrapper {
        justify-content: initial !important; 
        /* height: 100px !important; */
        height: 50px !important;
    }
   .uwy.userway_p5 .userway_buttons_wrapper {
     
        justify-content: initial !important;
    }
  

    body .uwy.userway_p7 .userway_buttons_wrapper {
        top: 13px;
        /* left: 13px; */
        right: auto;
        bottom: auto;
        right: 13px;
    }
    body  .userway_buttons_wrapper {
        top: 13px;
        /* left: 13px; */
        right: auto;
        bottom: auto;
        right: 13px;
    }
  }

  body .uwy.userway_p7 .userway_buttons_wrapper {
    top: 26px !important;
    bottom: auto;
    right: 13px !important;
}

.uwy.userway_p7 .userway_buttons_wrapper{
    left: unset !important;
    right: 13px !important;
}
 .uwaw .main{right: 0!important;}



 .card-thanks{
    /* background: #0D1C3D; */
    /* background: #0D1C3D; */
    background: rgba(13, 28, 61, 0.9);;
    color: #fff;
 }
 .more_normal{
    font-size:15px;
 }
 .card-send {
    background-color: #92CCF0;
    max-width: 150px;
    border-radius: 50px !important;
   cursor: pointer;
}
.bg-mamo-lady {
    background: url(../assets/images/Young-lady-100.jpg) no-repeat center / cover;
  
    background-position: center 8%;
}
.bg-mamo-card-2 {
    background: url(../assets/images/Old-lady-100.jpg) no-repeat center / cover;
    /* background: url(../assets/images/oldlady1.svg) no-repeat center / cover; */
    height: 100%;
    /* background-position: center 8%; */
    background-position: bottom;
}
.bg-lablady {
    background: url(../assets/images/Lablady.png) no-repeat center / cover;
    height: 100%;
    background-position: bottom;
}

.bg-fit-card-2 {
    background: url(../assets/images/oldlady.png) no-repeat center / cover;
    /* background: url(../assets/images/oldlady1.svg) no-repeat center / cover; */
    height: 100%;
    /* background-position: center 8%; */
    background-position: bottom;
}
.more_normal_one{
    font-size:12px;
}

.navigation_img_wrapper .navigation_img {
    width: 75px;
    height: auto;
}

.map_contact_no{
    border-bottom: 1px solid #001236;
}

.address_info_title{
    font-size: 1rem !important;
    color: #001236;
    font-family: AvenirMedium;
    margin-bottom: 0;
}

.address_info_desc{
    font-size: 0.9rem !important;
    color: #001236;
    font-family: AvenirRegular;
    margin-bottom: 0;
}

.lbl_map_txt{
    color: #0D1C3D;
    font-size: 1.2rem;
    font-family: AvenirMedium;
}

  

@media screen and (max-width: 576px) and (min-height: 650px) {
    .btn_bordered span{
        font-size: 20px;
        font-weight: 700 !important;
        color: #41618A;
        font-family: AvenirBold;
    }

    .address_info_title{
        font-size: 1.1rem !important;
    }

    .address_info_desc{
        font-size: 1rem !important;
    }

    .lbl_map_txt{
        font-size: 1.2rem !important;
    }

    .lbl_medium{
        font-size: 20px !important;
        font-family: AvenirRegular;
        line-height: 21px;
    }
    .lbl_hpv_medium{
        font-size: 20px !important;
        font-family: AvenirRegular;
        line-height: 26px;
    }

    .lbl_small{
        font-size: 15px;
        font-family: AvenirRegular;
    }
  
    .btn_global{
      /* width: 50%; */
      padding: 6px 15px;
      width: 60%;
  }
  
  .btn_global span{
    font-size: 20px;
    font-weight: 700 !important;
    color: #0C1C3C;
    font-family: AvenirBold;
    margin-top: 3px;
  }
}
@media screen and (max-width: 576px) and (min-height: 651px) and (max-height:750px) {
    .lbl_medium{
        font-size: 20px;
 
    }
}
@media screen and (max-width: 576px) and (min-height: 751px) and (max-height:800px) {
    .lbl_medium{
        font-size: 22px !important ;
        line-height:28px;
 
    }
}
@media screen and (max-width: 576px) and (min-height: 801px) and (max-height:850px) {
    .lbl_medium{
        font-size: 28px!important ;
        line-height:28px;
 
    }
}
@media screen and (max-width: 576px) and (min-height: 851px) and (max-height:950px) {
    .lbl_medium{
        font-size: 30px!important ;
        line-height:28px;
 
    }
}
@media screen and (max-width: 576px) and (min-height: 951px) and (max-height:1150px) {
    .lbl_medium{
        font-size: 30px!important ;
        line-height:28px;
 
    }
}
@media screen and (min-width: 576px) and (min-height: 951px) and (max-height:1150px) {
    .lbl_medium{
        font-size: 30px!important ;
        line-height:28px;
 
    }
}
@media screen and (min-width: 576px) and (min-height: 1151px) and (max-height:1450px) {
    .lbl_medium{
        font-size: 30px!important ;
        line-height:28px;
 
    }
}
.help-card-call-bottom {
    bottom: 15px!important;
}

.fitv2-videodes{
    font-size: 1.2rem;
    line-height: 1.4rem;
    color: #0D1C3D;
}

.fit_question{
    color: #0D1C3D;
}

.fit_question_text_wrapper{
    border-width:1px !important;
    background-color: "#fff" !important;
    margin:'auto' !important;
    width:"75%" !important;
    border-radius:12px !important;
    -webkit-border-radius:12px !important;
    -moz-border-radius:12px !important;
    -ms-border-radius:12px !important;
    -o-border-radius:12px !important;
}

.fit_question_text{
    font-weight: bold !important;
}

.appt_thanks {
    color:#003B71;
}