@font-face {
  font-family: AvenirRegular;
  src: url("./assets/fonts/AvenirNextLTPro-Regular.otf") format("opentype");
}

@font-face {
  font-family: AvenirBold;
  src: url("./assets/fonts/AvenirNextLTPro-Demi.otf") format("opentype");
}

@font-face {
  font-family: AvenirMedium;
  src: url("./assets/fonts/AvenirNextLTPro-Medium.otf") format("opentype");
}

body {
  margin: 0;
  font-family: AvenirRegular, -apple-system, BlinkMacSystemFont, 'Helvetica Neue', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h5{
  font-family: AvenirBold, -apple-system, BlinkMacSystemFont, 'Helvetica Neue', sans-serif !important;
}

p{
  margin-bottom: 0 !important;
}

button:not(.btn_form_btn){
  border-color: #0D1C3D !important;
}

.w-15{
  width:200px;
}

.react-datepicker__day{
  border: 1px solid #D3D6DC !important;
  border-radius: 50%;
  color: #284c78!important;
  font-family: 'AvenirBold';
}

.react-datepicker__day--selected{
  border-radius: 50% !important;
  color: white !important;
}

.react-datepicker__day--keyboard-selected{
background-color: white !important;
border-radius: 50% !important;
}

.react-datepicker__day--disabled{
  border-radius: 50% !important;
  background-color: #D3D6DC !important;
  color: #284c78 !important ;
  cursor: not-allowed !important;
}

.react-datepicker__header{
  background-color: white !important;
}

.react-datepicker__day-names, .react-datepicker__week{
  display: flex;
  justify-content: space-between;
}

.react-datepicker{
  font-family: AvenirRegular, -apple-system, BlinkMacSystemFont, 'Helvetica Neue', sans-serif !important;
  text-transform: uppercase;
  border: 0px !important;
}