.container {
  position: relative;
  height: 100vh;
  overflow: hidden;
}

.video-container {
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.video {
  width: 100%;
  height: 100%;
}

.line-height-normal{
  line-height: normal;
}

.retry_flag{
  position: absolute; 
  left: 0;
  top: 60px;
  width: 100%;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.retry_flag_inner{
  background-color: #0D1C3D;
  border-radius: 20px;
  padding: 2px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.primer_video_wrapper{
  display: flex;
  flex-direction: column;
  height: 100%;
  background: #000;
}

.primer_video_progress{
  position: absolute;
  bottom: 55px;
  width: 95%;
  z-index: 999;
  /* z-index: 1; */
  height: 10px;
  left: 50%;
  transform: translate(-50%, 0);
}

.retry_flag_img{
  width: 30px;
  height: 30px;
}

.retry_flag_txt{
  color: #fff;
  text-transform: uppercase;
  font-size: 1.2rem;
  margin-top: 4px;
}

.ctrl_text{
  font-size: 14px;
  color: #0D1C3D ;
  font-family: "AvenirRegular" !important;
  margin-top: 3px;
}

.controls {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  z-index: 1;
  background: #fff;
  height: 45px;
}

.button-container {
  width: 50%;
  bottom: 20px;
  display: flex;
  gap: 10px;
}

button {
  padding: 10px 20px;
  font-size: 16px;
  background-color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
}

button:hover {
  background-color:none;
  transform: scale(1.05);
}

@media (max-width: 768px) {
  button {
    padding: 8px 16px;
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  button {
    padding: 6px 12px;
    font-size: 12px;
  }

  .button-container {
    display: flex;
    gap: 5px;
    bottom: 10px;
    justify-content: center;
  }

  .primer_video_wrapper .video_fit{
    object-fit: contain !important;
  }
}

.controls_container {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  box-sizing: border-box;
  background: rgba(0, 0, 0, 0.5); 
}

.fit_af_am{
  display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 100%;
}
.fit_dyk{
  margin-top: 80px;
  margin-left: 15px;
  font-size: 38px;
  font-weight: 900!important;
  line-height: 40px;
  margin-bottom: 130px;
}

.fit-hispanic-dyk  {
  margin-top: 9px;
  margin-left: 6px;
  font-size: 35px;
  font-weight: 900 !important;
  line-height: 37px;
  margin-bottom: 130px;
}