.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.calendar-hint{
  font-size: 12px;
  text-align: right;
  margin-right: 10px;
}
.calendar-dropdown {
  position: absolute;
  right: 10px;
  top: calc(50% - 9px);
  display: inline-block;
  padding: 5px;
  width: 27px;
  height: 20px;
 background-image: url(../src/assets/images/calendar.svg);
}

.calender-picker {
  position: relative;
}

.mr-11{
margin-right: 11px !important;
}

.time-zone-container .time-zone-radio-group:nth-child(even) .time-zone-label {
  right: 0;
  left: inherit;
}

.time-zone-container .time-zone-radio-group:nth-child(even) .time-zone-radio {
  margin-left: 10px;
}

.time-zone-container .time-zone-radio-group:nth-child(even) {
  text-align: right;
}

body, html, #root, .container-fluid, .card-help, .card-body-h-vh {
  height: 100% !important;
  margin: 0;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.normal_text{
  font-size: 20px;
  font-weight: 500;
}
.normal_text1{
  font-size: 18px;
  font-weight: 500;
}

.not-allowed {
  cursor: not-allowed !important;
}
.normal_text_1{font-size: 18px; font-weight: 500; text-align: center;}
.p_header_text{font-size: 20px; font-weight: 600; text-align: center;}

.lbl_medium{
  font-size: 20px;
  font-family: AvenirRegular;
}

.lbl_small{
  font-size: 15px;
  font-family: AvenirRegular;
}

@media screen and (min-width: 1025px){
  .container-340 {
    width: 45%;
    margin:auto;
  }
  
  .custom_form_control {
    width: 45%;
    margin:auto;
  }

  .lbl_medium{
    font-size: 15px;
    font-family: AvenirRegular;
  }

  .lbl_small{
    font-size: 14px;
    font-family: AvenirRegular;
  }

  .bg-card1,.bg-card,.bg-card2,.bg-card3{
    margin-bottom: 20px!important;
  }

  .lbl_a1c_lab{
    font-size: 30px;
    font-family: AvenirRegular;
  }

  .presc_a1c_lab{
    font-size: 27px;
    font-family: AvenirRegular;
  }
}

@media screen and (max-width: 576px) {
  .normal_text{
    font-size: 20px;
    font-weight: 500;
  }

  .lbl_medium{
    font-size: 15px;
    font-family: AvenirRegular;
    line-height: 18px;
  }
  .lbl_small{
    font-size: 12px;
    font-family: AvenirRegular;
  }
  .lbl_a1c_lab{
    font-size: 1rem;
    font-family: AvenirRegular;
  }
  .presc_a1c_lab{
    font-size: 0.9rem;
    font-family: AvenirRegular;
  }
}