/* ErrorDialog.css */

.error-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 9999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.error-modal-content {
  background-color: #fefefe;
  padding: 20px;
  color: red;
  border-radius: 10px;
  /* max-width: 400px; */
  text-align: center;
  position: relative;
  width:298px
}

.close {
  color: #aaa;
  font-size: 28px;
  font-weight: bold;
  position: absolute;
  top: 10px;
  right: 15px;
  cursor: pointer;
}

.close:hover {
  color: black;
}
